import React, { useState, useEffect } from "react";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";

import DeliveryMemo from "./DeliveryMemo.js";
import BPL from "./BPL.js";
import DailyExp from "./DailyExp.js";
import DMHistory from "./DMHistory";
import BPLHistory from "./BPLHistory";
import DailyExpHistory from "./DailyExpHistory";

function BillManager() {
    useEffect(() => {}, []);

    const [value, setValue] = useState("1");

    const handleTabs = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <TabContext
            value={value}
            className="container-fluid border m-0 p-0 main"
        >
            <AppBar position="static" color="default">
                <TabList
                    onChange={handleTabs}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="New Delivery Memo" value="1" />
                    <Tab label="New BPL" value="2" />
                    <Tab label="New Daily Exp" value="3" />
                    <Tab label="Delivery memo History" value="4" />
                    <Tab label="BPL History" value="5" />
                    <Tab label="Daily Exp. History" value="6" />
                </TabList>
            </AppBar>
            <TabPanel
                value="1"
                className="container-fluid"
                style={{ padding: "15px 18px 0px 10px" }}
            >
                <DeliveryMemo />
            </TabPanel>

            <TabPanel
                value="2"
                className="container-fluid"
                style={{ padding: "15px 18px 0px 10px" }}
            >
                <BPL />
            </TabPanel>
            <TabPanel
                value="3"
                className="container-fluid"
                style={{ padding: "15px 18px 0px 10px" }}
            >
                <DailyExp />
            </TabPanel>

            <TabPanel
                value="4"
                className="container-fluid"
                style={{ padding: "10px 18px 0px 18px" }}
            >
                <DMHistory />
            </TabPanel>
            <TabPanel
                value="5"
                className="container-fluid"
                style={{ padding: "10px 18px 0px 18px" }}
            >
                <BPLHistory />
            </TabPanel>
            <TabPanel
                value="6"
                className="container-fluid"
                style={{ padding: "10px 18px 0px 18px" }}
            >
                <DailyExpHistory />
            </TabPanel>
        </TabContext>
    );
}

export default BillManager;
