import React, { Component } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { toast } from "react-toastify";

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

import moment from "moment";

import {
	Button,
} from "@material-ui/core";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";


import ViewBPL from "./ViewBPL";
import UpdateBPL from "./UpdateBPL";

//API handling components
import { API_URL } from "./../../../global";
const axios = require("axios");

export default class BPLHistory extends Component {

	constructor(props){
		super();

		this.state = {
			bplList: null
		}
	}

	fetchBPLList = () => {
		let url = API_URL;
		const query = `SELECT b.*, p.name as pname FROM capBoxPackingBill as b inner join party as p WHERE (b.partyId = p.id AND b.status = 1) ORDER BY b.id DESC;`;
		let data = { crossDomain: true, crossOrigin: true, query: query };
		axios
			.post(url, data)
			.then((res) => {
				console.log("deliveryBPLList: ", res.data);
				this.setState({ bplList: res.data});
				
				// init data table
				this.initializeDataTable();
			})
			.catch((err) => {
				console.log("deliveryBPL list fetch error: ", err);
			});
	}

	deleteRecord(id){
		let url = API_URL;
		const query = `UPDATE capBoxPackingBill SET status = 0  WHERE id=${id};`;
		let data = { crossDomain: true, crossOrigin: true, query: query };
		axios
			.post(url, data)
			.then((res) => {
				console.log("deleted status data: ", res.data);
				console.log("item deleted successfully");
				toast.error("Record deleted successfully");
				this.initializeData();
			})
			.catch((err) => {
				console.log("record delete error: ", err);
			});
	}

	initializeDataTable() {
		$('#BPLList').DataTable();
	}

	initializeData(){
		this.fetchBPLList();
	}
	componentDidMount(){
		this.initializeData();		
	}

	componentDidUpdate(){
		this.initializeDataTable();
	}
	
	renderBPLList = () => {

		if(this.state.bplList == null)
			return null;
		
		// else
		return this.state.bplList.map((bpl, index) => {
			return (
				<tr align="center" key={index}>
					<td>
						{bpl.id}
					</td>
					<td>
						{bpl.pname}
					</td>
					<td>
						{bpl.totalBoxes}
					</td>
					<td>
						{bpl.advance}
					</td>
					<td>
						{bpl.balance}
					</td>
					<td>
						{moment(bpl.date).format("DD / MM / YYYY")}
					</td>
					<td className="d-flex justify-content-center">

						<ViewBPL id={bpl.id} />
						&nbsp;
						<UpdateBPL id={bpl.id} initializeData={() => this.initializeData()}/>
						&nbsp;
						<Button
							className="mt-2"
							color="danger"
							variant="contained"
							onClick={(e) => {if(window.confirm('Delete the item?')){this.deleteRecord(bpl.id)};}}
						>
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					</td>
				</tr>
			);
		});
	}

	render(){
		return (
			<div>
				<Row>
					<Col
						md="12"
						className="m-0 p-1 measure1"
						style={{ minHeight: "85vh" }}
					>
						<div>
							<table
								id='BPLList'
								class="display" style={{width:"100%"}}
							>
								<thead>
									<tr align="center">
										<th align="center">BPL Id</th>
										<th align="center">Party Name</th>
										<th align="center">Total Boxes</th>
										<th align="center">Advance</th>
										<th align="center">Balance</th>
										<th align="center">Date</th>
										<th align="center">Actions</th>
									</tr>
								</thead>
								<tbody>	
									{this.renderBPLList()}	
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</div>	
		);
	}
}
