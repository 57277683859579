import React from "react";
import { Row, Col, Navbar } from "react-bootstrap";
import {
    Switch,
    HashRouter,
    Route,
    Redirect,
    Link,
    useRouteMatch,
} from "react-router-dom";
// import CSS styles
import "bootstrap/dist/css/bootstrap.css";

// import components
import NavbarPanel from "./NavbarPanel";
import PartyManager from "../party_manager/PartyManager";
import BTManager from "../box_tracking_manager/BTManager";
import LedgerManager from "../ledger_manager/LedgerManager";
import BillManager from "./../billManager/BillManager";
import Dashboard from "./dashboard.js";

function Main(props) {
    function logout() {}

    if (true) {
        //const { userName } = authenticated;
        const userName = "user1";
        return (
            <div>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand id="title">
                        <Link to="/main">
                            <span className="text-dark">
                                Diskha Vegetables |{" "}
                            </span>
                            <span className="text-success"> Capsicum</span>
                        </Link>
                    </Navbar.Brand>
                </Navbar>
                <HashRouter>
                    <div className="container-fluid m-0 p-0">
                        <Row className="m-0 p-0">
                            <Col className="col-sm-2 mt-1 pl-1 pr-1">
                                <NavbarPanel
                                    user={userName}
                                    logout={() => logout()}
                                />
                            </Col>
                            <Col className="col-sm-10 mt-1 p-0">
                                {/* Manager Routes */}
                                <Switch>
                                    <Route
                                        exact
                                        path="/dashboard"
                                        component={Dashboard}
                                    />
                                    <Route
                                        exact
                                        path="/mainDashboard"
                                        component={BillManager}
                                    />
                                    <Route
                                        path={`/partyManager`}
                                        exact
                                        component={PartyManager}
                                    />
                                    <Route
                                        path="/boxTrackingManager"
                                        exact
                                        component={BTManager}
                                    />

                                    <Route
                                        path="/ledgerManager/:partyId"
                                        exact
                                        component={LedgerManager}
                                    />
                                </Switch>
                            </Col>
                        </Row>
                    </div>
                </HashRouter>
            </div>
        );
    } else {
        return <Redirect to="/" />;
    }
}

export default Main;
