import React, { Component } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { toast } from "react-toastify";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import moment from "moment";

import { Button } from "@material-ui/core";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import ViewDailyExp from "./ViewDailyExp";
import UpdateDailyExp from "./UpdateDailyExp";

//API handling components
import { API_URL } from "./../../../global";
const axios = require("axios");

export default class DailyExpHistory extends Component {
    constructor(props) {
        super();

        this.state = {
            dailyExpList: null,
        };
    }

    fetchDailyExpList = () => {
        let url = API_URL;
        const query = `SELECT b.*, p.name as pname FROM capDailyExp as b inner join party as p WHERE (b.partyId = p.id AND b.status = 1) ORDER BY b.id DESC;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("dailyExpList: ", res.data);
                this.setState({ dailyExpList: res.data });

                // init data table
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("dailyExp list fetch error: ", err);
            });
    };

    deleteRecord(id) {
        let url = API_URL;
        const query = `UPDATE capDailyExp SET status = 0  WHERE id=${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                console.log("item deleted successfully");
                toast.error("Record deleted successfully");
                this.initializeData();
            })
            .catch((err) => {
                console.log("record delete error: ", err);
            });
    }

    initializeDataTable() {
        $("#DailyExpList").DataTable();
    }

    initializeData() {
        this.fetchDailyExpList();
    }
    componentDidMount() {
        this.initializeData();
    }

    componentDidUpdate() {
        this.initializeDataTable();
    }

    renderDailyExpList = () => {
        if (this.state.dailyExpList == null) return null;

        // else
        return this.state.dailyExpList.map((dailyExp, index) => {
            return (
                <tr align="center" key={index}>
                    <td>{dailyExp.id}</td>
                    <td>{dailyExp.pname}</td>
                    <td>{dailyExp.totalBoxes}</td>
                    <td>{dailyExp.totalAmount}</td>
                    <td>{dailyExp.vehicleNo}</td>
                    <td>{moment(dailyExp.date).format("DD / MM / YYYY")}</td>
                    <td className="d-flex justify-content-center">
                        <ViewDailyExp id={dailyExp.id} />
                        &nbsp;
                        {/* <UpdateDailyExp
                            id={dailyExp.id}
                            initializeData={() => this.initializeData()}
                        /> */}
                        &nbsp;
                        <Button
                            className="mt-2"
                            color="danger"
                            variant="contained"
                            onClick={(e) => {
                                if (window.confirm("Delete the item?")) {
                                    this.deleteRecord(dailyExp.id);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    render() {
        return (
            <div>
                <Row>
                    <Col
                        md="12"
                        className="m-0 p-1 measure1"
                        style={{ minHeight: "85vh" }}
                    >
                        <div>
                            <table
                                id="DailyExpList"
                                class="display"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr align="center">
                                        <th align="center">Daily Exp ID</th>
                                        <th align="center">Party Name</th>
                                        <th align="center">Total Boxes</th>
                                        <th align="center">Total Amount</th>
                                        <th align="center">Vahicle No</th>
                                        <th align="center">Date</th>
                                        <th align="center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderDailyExpList()}</tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
