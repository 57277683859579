import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "@material-ui/core";
import {
    Row,
    Col,
    Card,
    Badge,
    Table as Tbl,
    Form,
    Modal,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye,
    faPrint,
    faTrash,
    faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { API_URL } from "./../../../global";
const axios = require("axios");

export default class ViewBPL extends Component {
    constructor(props) {
        super();
        this.state = {
            modal: false,

            bplId: props.id,

            capBoxPackingBill: null,
            capBoxPackingList: null,

            partyId: 0,
            customerName: null,
            address: null,
            vehicleNo: null,
            vehicleDispatchTime: null,
            lorryFreight: null,
            transportName: null,
            inam1: null,
            inam2: null,
            driverMobile: null,
            date: null,
            advance: null,
            balance: null,

            itemList: [],
            totalBoxes: 0,

            varietyName: null,
            mark: null,
            boxQty: null,
            weightPerBox: null,
            total: 0,

            printRef: null,
        };
    }

    openModal = () => {
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    getBPL = () => {
        let url = API_URL;
        const query = `SELECT b.*, p.name as customerName, p.address as address FROM capBoxPackingBill as b inner join party as p where b.partyId = p.id AND b.id=${this.state.bplId}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("capBoxPackingBill data: ", res.data);
                this.setState({ capBoxPackingBill: res.data[0] });
            })
            .catch((err) => {
                console.log("capBoxPackingBill fetch error: ", err);
            });
    };

    getBPLList = () => {
        let url = API_URL;
        const query = `SELECT * FROM capBoxPackingList WHERE bplId=${this.state.bplId};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("capBoxPackingList data: ", res.data);
                this.setState({ capBoxPackingList: res.data });
            })
            .catch((err) => {
                console.log("capBoxPackingList fetch error: ", err);
            });
    };

    fetchInitialData() {
        this.getBPL();
        this.getBPLList();
    }
    componentDidMount() {
        this.fetchInitialData();
    }

    renderModal() {
        if (this.state.capBoxPackingBill == null) return null;

        return (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>BPL view #{this.state.capBoxPackingBill.id}</h5>
                    </Modal.Title>
                    <Button onClick={(e) => this.fetchInitialData()}>
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </Button>
                </Modal.Header>
                <Modal.Body ref={(el) => (this.componentRef = el)}>
                    <Card className="mt-2 p-0">
                        <Card.Header>
                            <p className="text-center pb-0 mb-4">
                                || श्री गणेश प्रसन्न &nbsp;&nbsp;&nbsp;&nbsp;
                                श्री खंडोबा प्रसन्न ||
                            </p>
                            <Card.Title className="text-center pb-0 mb-0">
                                <b>Diksha Vegetables</b>
                            </Card.Title>
                            <p className="text-center pb-0 mb-0">
                                Y. Mangewadi, Tal. Sangola, Dist. Solapur,
                                Maharashtra
                            </p>
                            <p className="text-center">
                                Pro. Ajay Yelpale &nbsp;&nbsp; Mob.No.
                                9623659519, 7218520520
                            </p>
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p>
                                    Date{" "}
                                    <b>
                                        {moment(
                                            this.state.capBoxPackingBill.date
                                        ).format("DD / MM / YYYY")}
                                    </b>
                                </p>

                                <p>
                                    Bill No.{" "}
                                    <b>{this.state.capBoxPackingBill.id}</b>
                                </p>
                            </span>
                            <span className="d-flex justify-content-center">
                                <div className="border border-danger rounded-pill px-5 text-danger">
                                    BOX PACKING LIST
                                </div>
                            </span>
                        </Card.Header>
                        <Card.Body className="pb-3 mb-0">
                            <div className="row">
                                <div className="col">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Merchant's name:{" "}
                                        {
                                            this.state.capBoxPackingBill
                                                .customerName
                                        }
                                    </h6>
                                </div>
                                <div className="col">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Address:{" "}
                                        {this.state.capBoxPackingBill.address}
                                    </h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Vehicle Number:{" "}
                                        {this.state.capBoxPackingBill.vehicleNo}
                                    </h6>
                                </div>
                                <div className="col">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Lorry Freight:{" "}
                                        {
                                            this.state.capBoxPackingBill
                                                .lorryFreight
                                        }
                                    </h6>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Body className="m-0 pt-0">
                            {/* Order overview */}
                            <Tbl striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Variety Name</th>
                                        <th>Mark</th>
                                        <th>Box Quantity</th>
                                        <th>Weight / Box</th>
                                    </tr>
                                </thead>
                                {this.state.capBoxPackingList != null &&
                                this.state.capBoxPackingList.length > 0 ? (
                                    <tbody>
                                        {this.state.capBoxPackingList.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.varietyName}{" "}
                                                        </td>
                                                        <td>{item.mark}</td>
                                                        <td>{item.boxQty}</td>
                                                        <td>
                                                            {item.weightPerBox}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">No items found</td>
                                        </tr>
                                    </tbody>
                                )}
                            </Tbl>
                        </Card.Body>
                        <Card.Footer className="pb-3 mb-0">
                            <div className="row">
                                <div className="col">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Total Boxes:{" "}
                                        {
                                            this.state.capBoxPackingBill
                                                .totalBoxes
                                        }
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Vehicle Dispatch Time :{" "}
                                        {moment(
                                            this.state.capBoxPackingBill
                                                .vehicleDispatchTime
                                        ).format("DD/MM/YYYY HH:MM")}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Inam 1<sup>st</sup> :{" "}
                                        {this.state.capBoxPackingBill.inam1}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Inam 2<sup>nd</sup> :{" "}
                                        {this.state.capBoxPackingBill.inam2}
                                    </h6>
                                </div>
                                <div className="col">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Advance :{" "}
                                        {this.state.capBoxPackingBill.advance}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Balance :{" "}
                                        {this.state.capBoxPackingBill.balance}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Transport name :{" "}
                                        {
                                            this.state.capBoxPackingBill
                                                .transportName
                                        }
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Driver mobile no. :{" "}
                                        {
                                            this.state.capBoxPackingBill
                                                .driverMobile
                                        }
                                    </h6>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                className="mt-2 mr-1"
                                color="primary"
                                variant="contained"
                                style={{ float: "right" }}
                            >
                                Print Bill
                            </Button>
                        )}
                        content={() => this.componentRef}
                    />
                </Modal.Footer>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    className="mt-2"
                    onClick={this.openModal}
                >
                    <FontAwesomeIcon icon={faEye} />
                </Button>

                <Modal
                    show={this.state.modal}
                    onHide={this.closeModal}
                    size="lg"
                >
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}
