import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { Row, Col, Card, Badge, Table as Tbl, Form } from "react-bootstrap";
import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./style.css";

import { API_URL } from "./../../../global";
const axios = require("axios");

export default class BPL extends Component {
    constructor(props) {
        super();

        this.state = {
            bplId: null,

            nameList: null,

            partyId: 0,
            customerName: null,
            address: null,
            vehicleNo: null,
            vehicleDispatchTime: null,
            lorryFreight: null,
            transportName: null,
            inam1: null,
            inam2: null,
            driverMobile: null,
            date: moment(new Date()).format("YYYY-MM-DD"),
            advance: null,
            balance: null,

            itemList: [],
            totalBoxes: 0,

            varietyName: null,
            mark: null,
            boxType: 1,
            boxQty: 0,
            weightPerBox: null,
            total: 0,

            count7Ply: 0,
            count9Ply: 0,
            countKhaki: 0,

            printRef: null,
        };
    }

    getLatestId = () => {
        let url = API_URL;
        const query = `SELECT id FROM capBoxPackingBill ORDER BY id DESC LIMIT 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("latest id data: ", res.data);
                this.setState({
                    bplId: (res.data[0] != null ? res.data[0]["id"] : 0) + 1,
                });
            })
            .catch((err) => {
                console.log("latest id fetch error: ", err);
            });
    };

    getIdNameList() {
        let url = API_URL;
        const query = `SELECT CONCAT(id, ', ', name) AS name FROM party;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("id+name data: ", res.data);
                this.setState({ nameList: res.data });
                console.log("id + name list: ", this.state.nameList);
            })
            .catch((err) => {
                console.log("id + name fetch error: ", err);
            });
    }

    addItems = () => {
        // fetch previous items
        let items = this.state.itemList;

        // update total
        let totalBoxes =
            Number(this.state.totalBoxes) + Number(this.state.boxQty);
        this.setState({ totalBoxes: totalBoxes });

        // update box count
        if (this.state.boxType === 1) {
            this.setState({
                count7Ply:
                    Number(this.state.count7Ply) + Number(this.state.boxQty),
            });
        } else if (this.state.boxType === 2) {
            this.setState({
                count9Ply:
                    Number(this.state.count9Ply) + Number(this.state.boxQty),
            });
        } else if (this.state.boxType === 3) {
            this.setState({
                countKhaki:
                    Number(this.state.countKhaki) + Number(this.state.boxQty),
            });
        }

        // add new item
        items.push({
            varietyName: this.state.varietyName,
            mark: this.state.mark,
            boxQty: this.state.boxQty,
            weightPerBox: this.state.weightPerBox,
        });

        // set state
        this.setState({ itemList: items });
    };

    deleteItem = (index) => {
        let itemList = this.state.itemList;

        // update total boxes
        let totalBoxes = this.state.totalBoxes - itemList[index]["boxQty"];
        this.setState({ totalBoxes: totalBoxes });

        // remove element
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ itemList: updatedList });
    };

    handleClear = () => {
        this.setState({ itemList: [] });
    };

    updateBoxCount = (type, count) => {
        // if count is not valid then simply return
        if (count < 1) return null;
        count = Number(count);
        const query = `UPDATE capBoxCount SET quantity = quantity - ${count} WHERE boxType = ${type}`;
        console.log(query);
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("box count updated successful");
            })
            .catch((err) => {
                console.log("failed to update box count, error: ", err);
            });
    };

    insertBPLList = () => {
        // 1.  insert into deliveryMemoList
        this.state.itemList.map((item, index) => {
            const query = `INSERT INTO capBoxPackingList(partyId, bplId, varietyName, mark, boxType, boxQty, weightPerBox) VALUES(${this.state.partyId}, ${this.state.latestInsertId}, '${item.varietyName}', '${item.mark}', ${this.state.boxType}, ${item.boxQty}, ${item.weightPerBox})`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(API_URL, data)
                .then((res) => {
                    console.log(
                        "insert boxPackingList successful, index: ",
                        index
                    );
                })
                .catch((err) => {
                    console.log(
                        "failed to insert boxPackingList, error: ",
                        err
                    );
                });
        });
    };

    handleSave = () => {
        // 1.  insert into deliveryMemo
        const query = `INSERT INTO capBoxPackingBill(partyId, date, vehicleNo, lorryFreight, driverMobile, vehicleDispatchTime, transportName, inam1, inam2, advance, balance, totalBoxes) VALUES(${this.state.partyId}, '${this.state.date}', '${this.state.vehicleNo}', ${this.state.lorryFreight},  '${this.state.driverMobile}', '${this.state.vehicleDispatchTime}', '${this.state.transportName}', '${this.state.inam1}', '${this.state.inam2}', ${this.state.advance}, ${this.state.balance}, ${this.state.totalBoxes})`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert BoxPackingBill successful");
                console.log("insert response: ", res.data.insertId);
                this.setState({ latestInsertId: res.data.insertId });
                this.insertBPLList();
                // update box count
                this.updateBoxCount(1, this.state.count7Ply);
                this.updateBoxCount(2, this.state.count9Ply);
                this.updateBoxCount(3, this.state.countKhaki);

                toast.success("BPL bill saved successfully");
            })
            .catch((err) => {
                console.log("failed to insert boxPackingBill, error: ", err);
            });
    };

    handleSavePrint = (e) => {
        // 1. handle save
        this.handleSave();

        // 2. handle print
        e.preventDefault();
    };

    componentDidMount() {
        this.getLatestId();
        this.getIdNameList();
    }

    render() {
        return (
            <div>
                <div autoComplete="off">
                    <FormControl
                        style={{ minWidth: "250px" }}
                        className="mr-2 mb-2 smt-0"
                    >
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={
                                this.state.nameList != null
                                    ? this.state.nameList.map(
                                          (item) => item.name
                                      )
                                    : []
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="party name"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            onChange={(event, value) => {
                                if (value != null && value.length > 2) {
                                    this.setState({
                                        partyId: value.split(",")[0],
                                    });
                                    this.setState({
                                        customerName: value.split(",")[1],
                                    });
                                }
                            }}
                        />
                    </FormControl>

                    <TextField
                        id="custAddress"
                        label="Address"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.address}
                        onChange={(e) => {
                            this.setState({ address: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="vehicleNo"
                        label="Vehicle Number"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.vehicleNo}
                        onChange={(e) => {
                            this.setState({ vehicleNo: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="vehicleDispatchTime"
                        type="datetime-local"
                        label="Vehicle Dispatch Time"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={
                            this.state.vehicleDispatchTime === null
                                ? ""
                                : this.state.vehicleDispatchTime
                        }
                        onChange={(e) => {
                            this.setState({
                                vehicleDispatchTime: e.target.value,
                            });
                        }}
                        required="true"
                        size="small"
                    />
                    <FormControl
                        variant="filled"
                        className="mr-2 mb-2"
                        style={{ minWidth: "180px" }}
                        size="small"
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Transport name
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => {
                                this.setState({
                                    transportName: e.target.value,
                                });
                            }}
                            name="transportName"
                            value={this.state.transportName}
                        >
                            <MenuItem value="Eicher">Eicher</MenuItem>
                            <MenuItem value="Truck">Truck</MenuItem>
                            <MenuItem value="Pikup">Pikup</MenuItem>
                            <MenuItem value="Train">Train</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="inam1"
                        type="text"
                        label="inam 1st"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.inam1}
                        onChange={(e) => {
                            this.setState({ inam1: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="inam1"
                        type="text"
                        label="inam 2nd"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.inam2}
                        onChange={(e) => {
                            this.setState({ inam2: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="frieght"
                        type="number"
                        label="Lorry Freight"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.lorryFreight}
                        onChange={(e) => {
                            this.setState({ lorryFreight: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="advance"
                        type="number"
                        label="Advance"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.advance}
                        onChange={(e) => {
                            this.setState({ advance: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="balance"
                        type="number"
                        label="Balance"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.balance}
                        onChange={(e) => {
                            this.setState({ balance: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="driverMobile"
                        type="text"
                        label="Driver Mobile No"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.driverMobile}
                        onChange={(e) => {
                            this.setState({ driverMobile: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="date"
                        label="Date"
                        variant="outlined"
                        type="date"
                        className="mr-2 mb-2"
                        value={this.state.date}
                        size="small"
                        onChange={(e) =>
                            this.setState({ date: e.target.value })
                        }
                    />
                    <hr />
                    <TextField
                        id="varietyName"
                        label="Variety Name"
                        variant="outlined"
                        type="text"
                        className="mr-2"
                        value={this.state.varietyName}
                        onChange={(e) => {
                            this.setState({ varietyName: e.target.value });
                        }}
                        size="small"
                    />
                    <TextField
                        id="mark"
                        label="Mark"
                        variant="outlined"
                        type="text"
                        className="mr-2"
                        value={this.state.mark}
                        onChange={(e) => {
                            this.setState({ mark: e.target.value });
                        }}
                        size="small"
                    />

                    <FormControl
                        variant="filled"
                        className="mr-2 mb-2"
                        style={{ minWidth: "180px" }}
                        size="small"
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Box type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => {
                                this.setState({
                                    boxType: e.target.value,
                                });
                            }}
                            name="transportName"
                            value={this.state.boxType}
                        >
                            <MenuItem value={1}>7 Ply</MenuItem>
                            <MenuItem value={2}>9 Ply</MenuItem>
                            <MenuItem value={3}>Khaki</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id="boxQty"
                        label="Box Quantity"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.boxQty}
                        onChange={(e) => {
                            this.setState({ boxQty: e.target.value });
                        }}
                        size="small"
                    />
                    <TextField
                        id="weightPerBox"
                        label="WeightPerBox"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.weightPerBox}
                        onChange={(e) => {
                            this.setState({ weightPerBox: e.target.value });
                        }}
                        size="small"
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.addItems}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                    </Button>

                    <div
                        className="mt-1 p-2 measure"
                        ref={(el) => (this.componentRef = el)}
                    >
                        <div className="row">
                            <div className="col col-md-8 mx-auto">
                                <Card className="mt-2 p-0">
                                    <Card.Header>
                                        <p className="text-center pb-0 mb-4">
                                            || श्री गणेश प्रसन्न
                                            &nbsp;&nbsp;&nbsp;&nbsp; श्री खंडोबा
                                            प्रसन्न ||
                                        </p>
                                        <Card.Title className="text-center pb-0 mb-0">
                                            <b>Diksha Vegetables</b>
                                        </Card.Title>
                                        <p className="text-center pb-0 mb-0">
                                            Y. Mangewadi, Tal. Sangola, Dist.
                                            Solapur, Maharashtra
                                        </p>
                                        <p className="text-center">
                                            Pro. Ajay Yelpale &nbsp;&nbsp;
                                            Mob.No. 9623659519, 7218520520
                                        </p>
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <p>
                                                Date{" "}
                                                <b>
                                                    {moment(
                                                        this.state.date
                                                    ).format("DD / MM / YYYY")}
                                                </b>
                                            </p>

                                            <p>
                                                Bill No.{" "}
                                                <b>{this.state.bplId}</b>
                                            </p>
                                        </span>
                                        <span className="d-flex justify-content-center">
                                            <div className="border border-danger rounded-pill px-5 text-danger">
                                                BOX PACKING LIST
                                            </div>
                                        </span>
                                    </Card.Header>
                                    <Card.Body className="pb-3 mb-0">
                                        <div className="row">
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Merchant's name:{" "}
                                                    {this.state.customerName}
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Address:{" "}
                                                    {this.state.address}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Vehicle Number:{" "}
                                                    {this.state.vehicleNo}
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Lorry Freight:{" "}
                                                    {this.state.lorryFreight}
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                    <Card.Body className="m-0 pt-0">
                                        {/* Order overview */}
                                        <Tbl striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Variety Name</th>
                                                    <th>Mark</th>
                                                    <th>Box Quantity</th>
                                                    <th>Weight / Box</th>
                                                </tr>
                                            </thead>
                                            {this.state.itemList.length > 0 ? (
                                                <tbody>
                                                    {this.state.itemList.map(
                                                        (item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {
                                                                            item.varietyName
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.mark
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.boxQty
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.weightPerBox
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        className="d-print-none"
                                                                        align="center"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                            onClick={() =>
                                                                                this.deleteItem(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="4">
                                                            No items added
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Tbl>
                                    </Card.Body>
                                    <Card.Footer className="pb-3 mb-0">
                                        <div className="row">
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Total Boxes:{" "}
                                                    {this.state.totalBoxes}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Vehicle Dispatch Time :{" "}
                                                    {
                                                        this.state
                                                            .vehicleDispatchTime
                                                    }
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Inam 1<sup>st</sup> :{" "}
                                                    {this.state.inam1}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Inam 2<sup>nd</sup> :{" "}
                                                    {this.state.inam2}
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Advance :{" "}
                                                    {this.state.advance}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Balance :{" "}
                                                    {this.state.balance}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Transport name :{" "}
                                                    {this.state.transportName}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Driver mobile no. :{" "}
                                                    {this.state.driverMobile}
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                className="mt-2 mr-1"
                                color="primary"
                                variant="contained"
                                style={{ float: "right" }}
                                disabled={
                                    this.state.customerName &&
                                    this.state.address
                                        ? false
                                        : true
                                }
                            >
                                Print Bill
                            </Button>
                        )}
                        content={() => this.componentRef}
                    />
                    <Button
                        className="mt-2 mr-1"
                        color="secondary"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={(e) => this.handleSave()}
                        disabled={
                            this.state.customerName && this.state.address
                                ? false
                                : true
                        }
                    >
                        Save bill
                    </Button>
                    <Button
                        className="mt-2 mr-1"
                        color="info"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleClear}
                    >
                        clear
                    </Button>
                </div>
            </div>
        );
    }
}
