import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import { toast } from "react-toastify";

import moment from "moment";

import {
	Button,
} from "@material-ui/core";


// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

import ViewDM from "./ViewDM";
import UpdateDM from "./UpdateDM";

//API handling components
import { API_URL } from "./../../../global";
const axios = require("axios");

export default class DMHistory extends Component {

	constructor(props){
		super();

		this.state = {
			memoList: null
		}
	}

	fetchMemoList = () => {
		let url = API_URL;
		const query = `SELECT dm.*, p.name as pname FROM capDeliveryMemo as dm inner join party as p where dm.partyId = p.id AND dm.status=1 ORDER BY dm.id DESC;`;
		let data = { crossDomain: true, crossOrigin: true, query: query };
		axios
			.post(url, data)
			.then((res) => {
				console.log("deliveryMemoList: ", res.data);
				this.setState({ memoList: res.data});
				
				// init data table
				this.initializeDataTable();
			})
			.catch((err) => {
				console.log("deliveryMemo list fetch error: ", err);
			});
	}

	deleteRecord(id){
		let url = API_URL;
		const query = `UPDATE capDeliveryMemo SET status = 0  WHERE id=${id};`;
		let data = { crossDomain: true, crossOrigin: true, query: query };
		axios
			.post(url, data)
			.then((res) => {
				console.log("deleted status data: ", res.data);
				console.log("item deleted successfully");
				toast.success("Record deleted successfully");
				this.fetchMemoList();
			})
			.catch((err) => {
				console.log("record delete error: ", err);
			});
	}

	initializeDataTable() {
		$(document).ready(function() {
			$('#memoList').DataTable();
		} );
	}

	initializeData(){
		this.fetchMemoList();
	}
	componentDidMount(){
		this.initializeData();			
	}

	renderMemoList = () => {

		if(this.state.memoList == null)
			return null;
		
		// else
		return this.state.memoList.map((memo) => {
			return (
				<tr align="center">
					<td>
						{memo.id}
					</td>
					<td>
						{memo.pname}
					</td>
					<td>
						{memo.total}
					</td>
					<td>
						{memo.advance}
					</td>
					<td>
						{memo.balance}
					</td>
					<td>
						{moment(memo.date).format("DD / MM / YYYY")}
					</td>
					<td className="d-flex justify-content-center">
						<ViewDM id={memo.id}/>
						&nbsp;
						<UpdateDM id={memo.id} pname={memo.pname} initializeData={() => this.initializeData()}/>
						&nbsp;
						<Button
							className="mt-2"
							color="danger"
							variant="contained"
							onClick={(e) => {if(window.confirm('Delete the item?')){this.deleteRecord(memo.id)};}}
						>
							<FontAwesomeIcon icon={faTrash} />
						</Button>
					</td>
				</tr>
			);
		});
	}

	render(){
		return (
			<div>
				<Row>
					<Col
						md="12"
						className="m-0 p-1 measure1"
						style={{ minHeight: "85vh" }}
					>
						<div>
							<table
								id='memoList'
								class="display" style={{width:"100%"}}
							>
								<thead>
									<tr align="center">
										<th align="center">Memo Id</th>
										<th align="center">Party Name</th>
										<th align="center">Total</th>
										<th align="center">Advance</th>
										<th align="center">Balance</th>
										<th align="center">Date</th>
										<th align="center">Actions</th>
									</tr>
								</thead>
								<tbody>	
									{this.renderMemoList()}	
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</div>	
		);
	}
}
