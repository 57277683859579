import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { Card, Table as Tbl } from "react-bootstrap";
import { TextField, Button, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./style.css";

import { API_URL } from "./../../../global";
const axios = require("axios");

export default class DailyExp extends Component {
    constructor(props) {
        super();

        this.state = {
            bplId: null,

            nameList: null,

            partyId: 0,
            customerName: null,
            address: null,
            vehicleNo: null,
            date: moment(new Date()).format("YYYY-MM-DD"),
            labour: 0,
            pickup: 0,

            itemList: [],
            totalBoxes: 0,
            totalAmount: 0,

            farmerId: null,
            farmerName: null,
            particular: null,
            rate: 0,
            boxQty: 0,
            weightPerBox: 0,
            amount: 0,

            printRef: null,
        };
    }

    getLatestId = () => {
        const query = `SELECT id FROM capDailyExp ORDER BY id DESC LIMIT 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("latest id data: ", res.data);
                this.setState({
                    bplId: (res.data[0] != null ? res.data[0]["id"] : 0) + 1,
                });
            })
            .catch((err) => {
                console.log("latest id fetch error: ", err);
            });
    };

    getIdNameList() {
        const query = `SELECT CONCAT(id, ', ', name) AS name FROM party ;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("id+name data: ", res.data);
                this.setState({ nameList: res.data });
                console.log("id + name list: ", this.state.nameList);
            })
            .catch((err) => {
                console.log("id + name fetch error: ", err);
            });
    }

    calculateAmount = (title, value) => {
        let amount = 0;
        if (title == "boxQty") {
            amount = this.state.weightPerBox * value * this.state.rate;
            this.setState({ boxQty: value });
        } else if (title == "weightPerBox") {
            amount = value * this.state.boxQty * this.state.rate;
            this.setState({ weightPerBox: value });
        } else if (title == "rate") {
            amount = this.state.weightPerBox * this.state.boxQty * value;
            this.setState({ rate: value });
        }
        this.setState({ amount: amount });
    };

    addItems = () => {
        // fetch previous items
        let items = this.state.itemList;

        // calculate & update totalBoxes
        let totalBoxes =
            Number(this.state.totalBoxes) + Number(this.state.boxQty);
        this.setState({ totalBoxes: totalBoxes });

        // calculate total weight
        let weight =
            Number(this.state.boxQty) * Number(this.state.weightPerBox);

        // calculate & update totalAmount
        let totalAmount =
            Number(this.state.totalAmount) + Number(this.state.amount);
        this.setState({ totalAmount: totalAmount });

        // add new item

        items.push({
            farmerId: this.state.farmerId,
            farmerName: this.state.farmerName,
            boxQty: this.state.boxQty,
            weightPerBox: Number(this.state.weightPerBox),
            weight: Number(weight),
            rate: Number(this.state.rate),
            amount: Number(this.state.amount),
        });

        // set state
        this.setState({ itemList: items });
    };

    deleteItem = (index) => {
        let itemList = this.state.itemList;

        // update total boxes
        let totalBoxes = this.state.totalBoxes - itemList[index]["boxQty"];
        this.setState({ totalBoxes: totalBoxes });

        // update total amount
        let totalAmount = this.state.totalAmount - itemList[index]["amount"];
        this.setState({ totalAmount: totalAmount });

        // remove element
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ itemList: updatedList });
    };

    handleClear = () => {
        this.setState({ itemList: [] });
    };

    insertIntoLedger = () => {
        // 1.  insert into Ledger
        this.state.itemList.map((item, index) => {
            if (item.farmerId > 0) {
                const query = `INSERT INTO ledger(party_id, particular, total, dailyExp_id) VALUES(${item.farmerId}, 'reference No.: ${this.state.latestInsertId}', ${item.amount}, ${this.state.latestInsertId})`;
                let data = {
                    crossDomain: true,
                    crossOrigin: true,
                    query: query,
                };
                axios
                    .post(API_URL, data)
                    .then((res) => {
                        console.log(
                            "insert into ledger successful, index: ",
                            index
                        );
                    })
                    .catch((err) => {
                        console.log(
                            "failed to insert into ledger, error: ",
                            err
                        );
                    });
            }
        });
    };

    insertDailyExpList = () => {
        // 1.  insert into deliveryMemoList
        this.state.itemList.map((item, index) => {
            const query = `INSERT INTO capDailyExpList(partyId, dailyExpId, farmerName, boxQty, weightPerBox, weight, rate, amount) VALUES(${this.state.partyId}, ${this.state.latestInsertId}, '${item.farmerName}', ${item.boxQty}, ${item.weightPerBox}, ${item.weight}, ${item.rate}, ${item.amount})`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            debugger;
            axios
                .post(API_URL, data)
                .then((res) => {
                    console.log(
                        "insert dailyExpList successful, index: ",
                        index
                    );
                })
                .catch((err) => {
                    console.log("failed to insert dailyExpList, error: ", err);
                });
        });
    };

    handleSave = () => {
        // 1.  insert into deliveryMemo
        const query = `INSERT INTO capDailyExp(partyId, date, vehicleNo, totalBoxes, labour, pickup, totalAmount) VALUES(${this.state.partyId}, '${this.state.date}', '${this.state.vehicleNo}', ${this.state.totalBoxes},  ${this.state.labour}, ${this.state.pickup}, ${this.state.totalAmount})`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert DailyExp successful");
                console.log("insert response: ", res.data.insertId);
                this.setState({ latestInsertId: res.data.insertId });
                this.insertDailyExpList();
                this.insertIntoLedger();
                toast.success("Daily exp. saved successfully");
            })
            .catch((err) => {
                console.log("failed to insert dailyExp, error: ", err);
            });
    };

    handleSavePrint = (e) => {
        // 1. handle save
        this.handleSave();

        // 2. handle print
        e.preventDefault();
    };

    componentDidMount() {
        this.getLatestId();
        this.getIdNameList();
    }

    render() {
        return (
            <div>
                <div autoComplete="off">
                    <FormControl
                        style={{ minWidth: "250px" }}
                        className="mr-2 mb-2 smt-0"
                    >
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={
                                this.state.nameList != null
                                    ? this.state.nameList.map(
                                          (item) => item.name
                                      )
                                    : []
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="party name"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            onChange={(event, value) => {
                                if (value != null && value.length > 2) {
                                    this.setState({
                                        partyId: value.split(",")[0],
                                    });
                                    this.setState({
                                        customerName: value.split(",")[1],
                                    });
                                }
                            }}
                        />
                    </FormControl>

                    <TextField
                        id="custAddress"
                        label="Address"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.address}
                        onChange={(e) => {
                            this.setState({ address: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="vehicleNo"
                        label="Vehicle Number"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.vehicleNo}
                        onChange={(e) => {
                            this.setState({ vehicleNo: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="date"
                        label="Date"
                        variant="outlined"
                        type="date"
                        className="mr-2 mb-2"
                        value={this.state.date}
                        size="small"
                        onChange={(e) =>
                            this.setState({ date: e.target.value })
                        }
                    />
                    <TextField
                        id="labour"
                        label="Labour"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.labour}
                        onChange={(e) => {
                            this.setState({ labour: e.target.value });
                        }}
                        size="small"
                    />
                    <TextField
                        id="pickup"
                        label="Pickup"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.pickup}
                        onChange={(e) => {
                            this.setState({ pickup: e.target.value });
                        }}
                        size="small"
                    />
                    <hr />

                    <FormControl
                        style={{ minWidth: "250px" }}
                        className="mr-2 mb-2 smt-0"
                    >
                        <Autocomplete
                            id="farmerName"
                            freeSolo
                            options={
                                this.state.nameList != null
                                    ? this.state.nameList.map(
                                          (item) => item.name
                                      )
                                    : []
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Farmer Name"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            onChange={(event, value) => {
                                console.log("farmer value", value);
                                if (
                                    value != null &&
                                    value.length > 2 &&
                                    value.indexOf(",") !== -1
                                ) {
                                    this.setState({
                                        farmerId: value.split(",")[0],
                                    });
                                    this.setState({
                                        farmerName: value.split(",")[1],
                                    });
                                } else {
                                    this.setState({
                                        farmerId: -1,
                                        farmerName: value,
                                    });
                                }
                            }}
                        />
                    </FormControl>

                    <TextField
                        id="boxQty"
                        label="Box Quantity"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.boxQty}
                        onChange={(e) => {
                            this.calculateAmount("boxQty", e.target.value);
                        }}
                        size="small"
                    />
                    <TextField
                        id="weightPerBox"
                        label="WeightPerBox"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.weightPerBox}
                        onChange={(e) => {
                            this.calculateAmount(
                                "weightPerBox",
                                e.target.value
                            );
                        }}
                        size="small"
                    />
                    <TextField
                        id="rate"
                        label="Rate"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.rate}
                        onChange={(e) => {
                            this.calculateAmount("rate", e.target.value);
                        }}
                        size="small"
                    />

                    <TextField
                        id="amount"
                        label="Amount"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.amount}
                        onChange={(e) => {
                            this.setState({ amount: e.target.value });
                        }}
                        size="small"
                    />

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.addItems}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                    </Button>

                    <div
                        className="mt-1 p-2 measure"
                        ref={(el) => (this.componentRef = el)}
                    >
                        <div className="row">
                            <div className="col col-md-8 mx-auto">
                                <Card className="mt-2 p-0">
                                    <Card.Header>
                                        <p className="text-center pb-0 mb-4">
                                            || श्री गणेश प्रसन्न
                                            &nbsp;&nbsp;&nbsp;&nbsp; श्री खंडोबा
                                            प्रसन्न ||
                                        </p>

                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <p>
                                                Date{" "}
                                                <b>
                                                    {moment(
                                                        this.state.date
                                                    ).format("DD / MM / YYYY")}
                                                </b>
                                            </p>

                                            <p>
                                                Bill No.{" "}
                                                <b>{this.state.bplId}</b>
                                            </p>
                                        </span>
                                        <span className="d-flex justify-content-center">
                                            <div className="border border-danger rounded-pill px-5 text-danger">
                                                DAILY EXPENSE BILL
                                            </div>
                                        </span>
                                    </Card.Header>
                                    <Card.Body className="pb-3 mb-0">
                                        <div className="row">
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Merchant's name:{" "}
                                                    {this.state.customerName}
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Address:{" "}
                                                    {this.state.address}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Vehicle Number:{" "}
                                                    {this.state.vehicleNo}
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                    <Card.Body className="m-0 pt-0">
                                        {/* Order overview */}
                                        <Tbl striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Farmer Name / particular
                                                    </th>
                                                    <th>Box Quantity</th>
                                                    <th>Weight / Box</th>
                                                    <th>Total weight</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            {this.state.itemList.length > 0 ? (
                                                <tbody>
                                                    {this.state.itemList.map(
                                                        (item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {
                                                                            item.farmerName
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.boxQty
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.weightPerBox
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.weight
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.rate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amount
                                                                        }
                                                                    </td>

                                                                    <td
                                                                        className="d-print-none"
                                                                        align="center"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                            onClick={() =>
                                                                                this.deleteItem(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="6">
                                                            No items added
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Tbl>
                                    </Card.Body>
                                    <Card.Footer className="pb-3 mb-0">
                                        <div className="row">
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Labour : {this.state.labour}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Pickup : {this.state.pickup}
                                                </h6>
                                            </div>
                                            <div className="col">
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Total Boxes:{" "}
                                                    {this.state.totalBoxes}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Total Amount :{" "}
                                                    {this.state.totalAmount +
                                                        Number(
                                                            this.state.pickup
                                                        )}
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                className="mt-2 mr-1"
                                color="primary"
                                variant="contained"
                                style={{ float: "right" }}
                                disabled={
                                    this.state.customerName &&
                                    this.state.address
                                        ? false
                                        : true
                                }
                            >
                                Print Bill
                            </Button>
                        )}
                        content={() => this.componentRef}
                    />
                    <Button
                        className="mt-2 mr-1"
                        color="secondary"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={(e) => this.handleSave()}
                        disabled={
                            this.state.customerName && this.state.address
                                ? false
                                : true
                        }
                    >
                        Save bill
                    </Button>
                    <Button
                        className="mt-2 mr-1"
                        color="info"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleClear}
                    >
                        clear
                    </Button>
                </div>
            </div>
        );
    }
}
