import React, { Component } from "react";

import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@material-ui/core";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "./../../../../node_modules/react-toastify/dist/ReactToastify.css";

//API handling components
import { API_URL } from "./../../../global";
const axios = require("axios");

export class AddNewEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            supplier: null,
            boxType: 0,
            quntity: 0,
            amount: 0,
            paid: 0,
            pending: 0,
        };
    }

    updateBoxCount() {
        const query = `UPDATE capBoxCount SET quantity = quantity + ${this.state.quantity} WHERE boxType=${this.state.boxType};`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("Box count updated successfully");
                setTimeout(() => {
                    this.props.refreshLedger();
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleAddSubmit(e) {
        e.preventDefault();
        const query = `INSERT INTO capBTLedger( supplier, boxType, quantity, amount, paid, pending) VALUES('${this.state.supplier}', ${this.state.boxType}, ${this.state.quantity}, ${this.state.amount}, ${this.state.paid}, ${this.state.pending});`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("BTledger record added successfully");
                toast.success("Box Tracking record added successfully");
                this.updateBoxCount();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="row">
                <form autoComplete="off">
                    <div className="row ml-4 mt-4">
                        <TextField
                            id="supplier"
                            label="Supplier"
                            variant="outlined"
                            type="text"
                            className="mr-2"
                            required={true}
                            size="small"
                            onChange={(e) =>
                                this.setState({ supplier: e.target.value })
                            }
                        />
                        <FormControl
                            variant="filled"
                            className="mr-2 mb-2"
                            style={{ minWidth: "150px" }}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Box Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                onChange={(e) => {
                                    this.setState({
                                        boxType: e.target.value,
                                    });
                                }}
                                name="boxType"
                                value={this.state.boxType}
                            >
                                <MenuItem value={1}>7 ply</MenuItem>
                                <MenuItem value={2}>9 Ply</MenuItem>
                                <MenuItem value={3}>Khaki</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            id="quantity"
                            label="Quantity"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ quantity: e.target.value })
                            }
                        />

                        <TextField
                            id="amount"
                            label="amount"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ amount: e.target.value })
                            }
                        />
                        <TextField
                            id="paid"
                            label="paid"
                            variant="outlined"
                            className="mr-2"
                            type="number"
                            size="small"
                            onChange={(e) =>
                                this.setState({ paid: e.target.value })
                            }
                        />
                        <TextField
                            id="pending"
                            label="pending"
                            variant="outlined"
                            className="mr-2"
                            type="number"
                            size="small"
                            value={this.state.pending}
                            onChange={(e) =>
                                this.setState({ pending: e.target.value })
                            }
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            className=""
                            className="mb-3"
                            onClick={(e) => this.handleAddSubmit(e)}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            className="mb-3 ml-2"
                            onClick={this.props.refreshLedger}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
