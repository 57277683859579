import React, { Component } from "react";
import ReactToPrint from "react-to-print";

import {
    Row,
    Col,
    Card,
    Badge,
    Table as Tbl,
    Form,
    Modal,
} from "react-bootstrap";

import { TextField, Button, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenAlt,
    faPlusCircle,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { API_URL } from "./../../../global";
const axios = require("axios");

export default class UpdateDM extends Component {
    constructor(props) {
        super();
        this.state = {
            modal: false,

            id: props.id,
            initializeData: props.initializeData,

            capDeliveryMemo: null,
            capDeliveryMemoList: null,

            memoId: null,
            partyId: 0,
            customerName: props.pname,
            address: null,
            vehicleNo: null,
            lorryFreight: null,
            driverMobile: null,
            date: null,

            particular: null,
            mark: null,
            totalBoxes: 0,
            weightPerBox: 0,
            weight: 0,
            rate: 0,
            amount: 0,

            advance: 0,
            total: 0,
            balance: 0,

            printRef: null,

            nameList: null,
        };
    }

    openModal = () => {
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    presetOldData = () => {
        this.setState({ partyId: this.state.capDeliveryMemo.partyId });
        this.setState({ address: this.state.capDeliveryMemo.address });
        this.setState({ vehicleNo: this.state.capDeliveryMemo.vehicleNo });
        this.setState({
            lorryFreight: this.state.capDeliveryMemo.lorryFreight,
        });
        this.setState({
            driverMobile: this.state.capDeliveryMemo.driverMobile,
        });
        this.setState({ advance: this.state.capDeliveryMemo.advance });
        this.setState({ balance: this.state.capDeliveryMemo.balance });
        this.setState({ total: this.state.capDeliveryMemo.total });
        this.setState({ date: this.state.capDeliveryMemo.date });
    };

    getDM = () => {
        let url = API_URL;
        const query = `SELECT b.*, p.name as customerName, p.address as address FROM capDeliveryMemo as b inner join party as p where b.partyId = p.id AND b.id=${this.state.id}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("capDeliveryMemo data: ", res.data);
                this.setState({ capDeliveryMemo: res.data[0] });
                this.presetOldData();
            })
            .catch((err) => {
                console.log("capDeliveryMemo fetch error: ", err);
            });
    };

    getDMList = () => {
        let url = API_URL;
        const query = `SELECT * FROM capDeliveryMemoList WHERE deliveryMemoId=${this.state.id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("capDeliveryMemoList data: ", res.data);
                this.setState({ capDeliveryMemoList: res.data });
            })
            .catch((err) => {
                console.log("capDeliveryMemoList fetch error: ", err);
            });
    };

    caluclateWeight = (field, value) => {
        if (field === "totalBoxes") {
            let weight = value * this.state.weightPerBox;
            this.setState({ totalBoxes: value });
            this.setState({ weight: weight });
        } else if (field === "weightPerBox") {
            let weight = this.state.totalBoxes * value;
            this.setState({ weightPerBox: value });
            this.setState({ weight: weight });
        }
    };

    calculateAmount = (field, value) => {
        if (field === "weight") {
            let amount = value * this.state.rate;
            this.setState({ weight: value });
            this.setState({ amount: amount });
        } else if (field === "rate") {
            let amount = this.state.weight * value;
            this.setState({ rate: value });
            this.setState({ amount: amount });
        }
    };

    addItems = () => {
        let items = this.state.capDeliveryMemoList;
        items.push({
            particular: this.state.particular,
            mark: this.state.mark,
            totalBoxes: this.state.totalBoxes,
            weightPerBox: this.state.weightPerBox,
            weight: this.state.weight,
            rate: this.state.rate,
            amount: this.state.amount,
        });

        this.setState({ capDeliveryMemoList: items });

        // update total & balance
        let total = this.state.total;
        total += this.state.amount;
        this.setState({ total: total });
        let balance = total - this.state.advance;
        this.setState({ balance: balance });
    };

    deleteItem = (index) => {
        let capDeliveryMemoList = this.state.capDeliveryMemoList;

        // update total & balance
        let total = this.state.total;
        total -= capDeliveryMemoList[index]["amount"];
        let balance = total - this.state.advance;
        this.setState({ total: total });
        this.setState({ balance: balance });

        // remove element
        let updatedList = capDeliveryMemoList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ capDeliveryMemoList: updatedList });
    };

    updateDeliveryMemoList = () => {
        let url = API_URL;

        // 2. delete previous
        this.state.capDeliveryMemoList.map((item, index) => {
            const query = `DELETE FROM capDeliveryMemoList WHERE partyId=${this.state.partyId} AND deliveryMemoId=${this.state.id}`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "deleted previous records from deliveryMemoList successfully"
                    );
                })
                .catch((err) => {
                    console.log(
                        "failed to delete previous records from deliveryMemoList, error: ",
                        err
                    );
                });
        });

        // 1.  insert updated list into deliveryMemoList
        this.state.capDeliveryMemoList.map((item, index) => {
            const query = `INSERT INTO capDeliveryMemoList(partyId, deliveryMemoId, particular, mark, totalBoxes, weightPerBox, weight, rate, amount) VALUES(${this.state.partyId}, ${this.state.id}, '${item.particular}', '${item.mark}', ${item.totalBoxes}, ${item.weightPerBox}, ${item.weight}, ${item.rate}, ${item.amount})`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "insert into deliveryMemoList successful, index: ",
                        index
                    );
                })
                .catch((err) => {
                    console.log(
                        "failed to insert into deliveryMemoList, error: ",
                        err
                    );
                });
        });
    };

    handleUpdate = () => {
        let url = API_URL;

        // 1.  insert into deliveryMemo
        const query = `UPDATE capDeliveryMemo SET date='${this.state.date}', vehicleNo='${this.state.vehicleNo}', lorryFreight=${this.state.lorryFreight}, driverMobile='${this.state.driverMobile}', total=${this.state.total}, advance=${this.state.advance}, balance=${this.state.balance} WHERE id=${this.state.id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("update deliveryMemo successful");
                console.log("update response: ", res.data.insertId);
                this.updateDeliveryMemoList();
                toast.success("Delivery memo updated successfully");
                this.state.initializeData();
            })
            .catch((err) => {
                console.log("failed to insert deliveryMemo, error: ", err);
            });
    };

    handleUpdatePrint = (e) => {
        // 1. handle update
        this.handleUpdate();

        // 2. handle print
    };

    componentDidMount() {
        this.getDM();
        this.getDMList();
    }

    renderModal() {
        if (this.state.capDeliveryMemo == null) return null;

        return (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Delivery Memo #{this.state.capDeliveryMemo.id}</h5>
                    </Modal.Title>
                </Modal.Header>

                <div className="m-2">
                    <TextField
                        id="customerName"
                        label="Party Name"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.customerName}
                        size="small"
                        onChange={(e) =>
                            this.setState({ customerName: e.target.value })
                        }
                        required="true"
                        disabled
                    />

                    <TextField
                        id="customerAddress"
                        label="Address"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.address}
                        size="small"
                        onChange={(e) =>
                            this.setState({ address: e.target.value })
                        }
                        required="true"
                    />

                    <TextField
                        id="vehicleNo"
                        label=" VehicleNo"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.vehicleNo}
                        size="small"
                        onChange={(e) =>
                            this.setState({ vehicleNo: e.target.value })
                        }
                    />

                    <TextField
                        id="lorryFreight"
                        label="Lorry Freight"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.lorryFreight}
                        size="small"
                        onChange={(e) =>
                            this.setState({ lorryFreight: e.target.value })
                        }
                    />

                    <TextField
                        id="driverMobile"
                        label="Driver Mobile No."
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.driverMobile}
                        size="small"
                        onChange={(e) =>
                            this.setState({ driverMobile: e.target.value })
                        }
                    />

                    <TextField
                        id="advance"
                        label="Advance"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.advance}
                        size="small"
                        onChange={(e) =>
                            this.setState({ advance: e.target.value })
                        }
                    />

                    <TextField
                        id="date"
                        label="Date"
                        variant="outlined"
                        type="date"
                        className="mr-2 mb-2"
                        value={this.state.date}
                        size="small"
                        onChange={(e) =>
                            this.setState({ date: e.target.value })
                        }
                    />

                    <hr />
                    <TextField
                        id="particular"
                        label="Particular"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.particular}
                        size="small"
                        onChange={(e) =>
                            this.setState({ particular: e.target.value })
                        }
                    />
                    <TextField
                        id="mark"
                        label="Mark"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.mark}
                        size="small"
                        onChange={(e) =>
                            this.setState({ mark: e.target.value })
                        }
                    />

                    <TextField
                        id="totalBoxes"
                        label="Total Boxes"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.totalBoxes}
                        size="small"
                        onChange={(e) =>
                            this.caluclateWeight("totalBoxes", e.target.value)
                        }
                    />
                    <TextField
                        id="weightPerBox"
                        label="Weight/Box"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.weightPerBox}
                        size="small"
                        shrink="true"
                        onChange={(e) =>
                            this.caluclateWeight("weightPerBox", e.target.value)
                        }
                    />
                    <TextField
                        id="weight"
                        label="Weight"
                        variant="outlined"
                        type="number"
                        className="mr-2 mb-2"
                        value={this.state.weight}
                        size="small"
                        shrink="true"
                        onChange={(e) =>
                            this.calculateAmount("weight", e.target.value)
                        }
                    />
                    <TextField
                        id="rate"
                        label="Rate"
                        variant="outlined"
                        type="number"
                        className="mr-2 mb-2"
                        value={this.state.rate}
                        size="small"
                        onChange={(e) =>
                            this.calculateAmount("rate", e.target.value)
                        }
                    />
                    <TextField
                        id="amount"
                        label="Amount"
                        variant="outlined"
                        type="number"
                        className="mr-2 mb-2"
                        value={this.state.amount}
                        size="small"
                        onChange={(e) =>
                            this.setState({ amount: e.target.value })
                        }
                    />

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.addItems}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                    </Button>
                </div>
                <Modal.Body ref={(el) => (this.componentRef = el)}>
                    <Card className="mt-2 p-0">
                        <Card.Header>
                            <p className="text-center pb-0 mb-4">
                                || श्री गणेश प्रसन्न &nbsp;&nbsp;&nbsp;&nbsp;
                                श्री खंडोबा प्रसन्न ||
                            </p>
                            <Card.Title className="text-center pb-0 mb-0">
                                <b>Diksha Vegetables</b>
                            </Card.Title>
                            <p className="text-center pb-0 mb-0">
                                Y. Mangewadi, Tal. Sangola, Dist. Solapur,
                                Maharashtra
                            </p>
                            <p className="text-center">
                                Pro. Ajay Yelpale &nbsp;&nbsp; Mob.No.
                                9623659519, 7218520520
                            </p>
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p>
                                    Date:{" "}
                                    <b>
                                        {moment(this.state.date).format(
                                            "DD / MM / YYYY"
                                        )}
                                    </b>
                                </p>

                                <p>
                                    Bill No.: <b>{this.state.id}</b>
                                </p>
                            </span>
                            <span className="d-flex justify-content-center">
                                <div className="border border-danger rounded-pill px-5 text-danger">
                                    DELIVERY MEMO
                                </div>
                            </span>
                        </Card.Header>
                        <Card.Body className="pb-3 mb-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Merchant's name:{" "}
                                        {
                                            this.state.capDeliveryMemo
                                                .customerName
                                        }
                                    </h6>
                                </div>
                                <div className="col-md-6">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Vehicle Number: {this.state.vehicleNo}
                                    </h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Address: {this.state.address}
                                    </h6>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Body className="m-0 pt-0">
                            {/* Order overview */}
                            <Tbl striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Particular</th>
                                        <th>Mark</th>
                                        <th>Total boxes</th>
                                        <th>Weight/Box</th>
                                        <th>Total Weight</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                {this.state.capDeliveryMemoList != null ? (
                                    <tbody>
                                        {this.state.capDeliveryMemoList.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1} </td>
                                                        <td>
                                                            {item.particular}{" "}
                                                        </td>
                                                        <td>{item.mark} </td>
                                                        <td>
                                                            {item.totalBoxes}
                                                        </td>
                                                        <td>
                                                            {item.weightPerBox}
                                                        </td>
                                                        <td>{item.weight}</td>
                                                        <td>{item.rate}</td>
                                                        <td>{item.amount}</td>
                                                        <td
                                                            className="d-print-none"
                                                            align="center"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                onClick={() =>
                                                                    this.deleteItem(
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">No items added</td>
                                        </tr>
                                    </tbody>
                                )}
                            </Tbl>
                        </Card.Body>
                        <Card.Footer className="pb-3 mb-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Advance: {this.state.advance}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Balance: {this.state.balance}
                                    </h6>
                                </div>
                                <div className="col-md-6">
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Lorry freight: {this.state.lorryFreight}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Driver Mobile No:{" "}
                                        {this.state.driverMobile}
                                    </h6>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="mt-2 mr-1"
                        color="secondary"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleUpdate}
                    >
                        Update bill
                    </Button>
                </Modal.Footer>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Button
                    color="secondary"
                    variant="contained"
                    className="mt-2"
                    onClick={this.openModal}
                >
                    <FontAwesomeIcon icon={faPenAlt} />
                </Button>

                <Modal
                    show={this.state.modal}
                    onHide={this.closeModal}
                    size="xl"
                    dialogClassName="modal-90w"
                >
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}
