import React, { Component, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import { TextField, Button, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./style.css";

import { API_URL } from "./../../../global";

const axios = require("axios");

export default class DeliveryMemo extends Component {
    constructor(props) {
        super();

        this.state = {
            memoId: null,
            partyId: 0,
            customerName: null,
            address: null,
            vehicleNo: null,
            lorryFreight: null,
            driverMobile: null,
            date: moment(new Date()).format("YYYY-MM-DD"),

            particular: null,
            mark: null,
            totalBoxes: 0,
            weightPerBox: 0,
            weight: 0,
            rate: 0,
            amount: 0,

            itemList: [],

            advance: 0,
            total: 0,
            balance: 0,

            printComponentRef: null,

            nameList: null,

            latestInsertId: 0,
        };
    }

    getIdNameList() {
        let url = API_URL;
        const query = `SELECT CONCAT(id, ', ', name) AS name FROM party;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("id+name data: ", res.data);
                this.setState({ nameList: res.data });
                console.log("id + name list: ", this.state.nameList);
            })
            .catch((err) => {
                console.log("id + name fetch error: ", err);
            });
    }

    getLatestId = () => {
        let url = API_URL;
        const query = `SELECT id FROM capDeliveryMemo ORDER BY id DESC LIMIT 1;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("latest id data: ", res.data);
                this.setState({
                    memoId: (res.data[0] != null ? res.data[0]["id"] : 0) + 1,
                });
            })
            .catch((err) => {
                console.log("party data fetch error: ", err);
            });
    };

    caluclateWeight = (field, value) => {
        if (field === "totalBoxes") {
            let weight = value * this.state.weightPerBox;
            this.setState({ totalBoxes: value });
            this.setState({ weight: weight });
        } else if (field === "weightPerBox") {
            let weight = this.state.totalBoxes * value;
            this.setState({ weightPerBox: value });
            this.setState({ weight: weight });
        }
    };

    calculateAmount = (field, value) => {
        if (field === "weight") {
            let amount = value * this.state.rate;
            this.setState({ weight: value });
            this.setState({ amount: amount });
        } else if (field === "rate") {
            let amount = this.state.weight * value;
            this.setState({ rate: value });
            this.setState({ amount: amount });
        }
    };

    addItems = () => {
        let items = this.state.itemList;
        items.push({
            particular: this.state.particular,
            mark: this.state.mark,
            totalBoxes: this.state.totalBoxes,
            weightPerBox: this.state.weightPerBox,
            weight: this.state.weight,
            rate: this.state.rate,
            amount: this.state.amount,
        });

        this.setState({ itemList: items });

        // update total & balance
        let total = Number(this.state.total) + Number(this.state.amount);
        this.setState({ total: total });
        let balance = total + Number(this.state.advance);
        this.setState({ balance: balance });
    };

    deleteItem = (index) => {
        let itemList = this.state.itemList;

        // update total & balance
        let total = this.state.total - itemList[index]["amount"];
        let balance = total + Number(this.state.advance);
        this.setState({ total: total });
        this.setState({ balance: balance });

        // remove element
        let updatedList = itemList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ itemList: updatedList });
    };

    handleClear = () => {
        return null;
    };

    insertDeliveryMemoList = () => {
        let url = API_URL;

        // 1.  insert into deliveryMemoList
        this.state.itemList.map((item, index) => {
            const query = `INSERT INTO capDeliveryMemoList(partyId, deliveryMemoId, particular, mark, totalBoxes, weightPerBox, weight, rate, amount) VALUES(${this.state.partyId}, ${this.state.latestInsertId}, '${item.particular}', '${item.mark}', ${item.totalBoxes}, ${item.weightPerBox}, ${item.weight}, ${item.rate}, ${item.amount})`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "insert deliveryMemoList successful, index: ",
                        index
                    );
                })
                .catch((err) => {
                    console.log("failed to insert deliveryMemo, error: ", err);
                });
        });
    };

    insertLedgerRecord = () => {
        // 1.  insert into ledger
        const query = `INSERT INTO ledger(party_id, particular, total, memo_id) VALUES(${this.state.partyId}, 'reference memo id: ${this.state.latestInsertId}', ${this.state.total}, ${this.state.latestInsertId})`;

        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert ledger successful");
                console.log("insert response: ", res.data.insertId);
            })
            .catch((err) => {
                console.log("failed to insert ledger, error: ", err);
            });
    };

    handleSave = () => {
        // 1.  insert into deliveryMemo
        const query = `INSERT INTO capDeliveryMemo(partyId, date, vehicleNo, lorryFreight, driverMobile, total, advance, balance) VALUES(${this.state.partyId}, '${this.state.date}', '${this.state.vehicleNo}', ${this.state.lorryFreight}, '${this.state.driverMobile}', ${this.state.total}, ${this.state.advance}, ${this.state.balance})`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("insert deliveryMemo successful");
                console.log("insert response: ", res.data.insertId);
                this.setState({ latestInsertId: res.data.insertId });
                this.insertDeliveryMemoList();
                this.insertLedgerRecord();
                toast.success("Delivery memo saved successfully");
            })
            .catch((err) => {
                console.log("failed to insert deliveryMemo, error: ", err);
            });
    };

    handleSavePrint = (e) => {
        console.log("in handle save print");
        // 1. handle save
        this.handleSave();
    };

    componentDidMount() {
        this.getLatestId();
        this.getIdNameList();
    }

    render() {
        return (
            <div style={{ marginBottom: "25px" }}>
                <div autoComplete="off">
                    <FormControl
                        style={{ minWidth: "250px" }}
                        className="mr-2 smt-0 pt-0"
                        size="small"
                    >
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={
                                this.state.nameList != null
                                    ? this.state.nameList.map(
                                          (item) => item.name
                                      )
                                    : []
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Party name"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            onChange={(event, value) => {
                                this.setState({ partyId: value.split(",")[0] });
                                this.setState({
                                    customerName: value.split(",")[1],
                                });
                            }}
                        />
                    </FormControl>

                    <TextField
                        id="customerAddress"
                        label="Address"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.address}
                        size="small"
                        onChange={(e) =>
                            this.setState({ address: e.target.value })
                        }
                        required="true"
                    />
                    <TextField
                        id="vehicleNo"
                        label=" VehicleNo"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.vehicleNo}
                        size="small"
                        onChange={(e) =>
                            this.setState({ vehicleNo: e.target.value })
                        }
                    />
                    <TextField
                        id="lorryFreight"
                        label="Lorry Freight"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.lorryFreight}
                        size="small"
                        onChange={(e) =>
                            this.setState({ lorryFreight: e.target.value })
                        }
                    />
                    <TextField
                        id="driverMobile"
                        label="Driver Mobile No."
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.driverMobile}
                        size="small"
                        onChange={(e) =>
                            this.setState({ driverMobile: e.target.value })
                        }
                    />
                    <TextField
                        id="advance"
                        label="Advance"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.advance}
                        size="small"
                        onChange={(e) =>
                            this.setState({ advance: e.target.value })
                        }
                    />
                    <TextField
                        id="date"
                        label="Date"
                        variant="outlined"
                        type="date"
                        className="mr-2 mb-2"
                        value={this.state.date}
                        size="small"
                        onChange={(e) =>
                            this.setState({ date: e.target.value })
                        }
                    />
                    <hr />
                    <TextField
                        id="particular"
                        label="Particular"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.particular}
                        size="small"
                        onChange={(e) =>
                            this.setState({ particular: e.target.value })
                        }
                    />
                    <TextField
                        id="mark"
                        label="Mark"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.mark}
                        size="small"
                        onChange={(e) =>
                            this.setState({ mark: e.target.value })
                        }
                    />
                    <TextField
                        id="totalBoxes"
                        label="Total Boxes"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.totalBoxes}
                        size="small"
                        onChange={(e) =>
                            this.caluclateWeight("totalBoxes", e.target.value)
                        }
                    />
                    <TextField
                        id="weightPerBox"
                        label="Weight/Box"
                        variant="outlined"
                        type="text"
                        className="mr-2 mb-2"
                        value={this.state.weightPerBox}
                        size="small"
                        shrink="true"
                        onChange={(e) =>
                            this.caluclateWeight("weightPerBox", e.target.value)
                        }
                    />
                    <TextField
                        id="weight"
                        label="Weight"
                        variant="outlined"
                        type="number"
                        className="mr-2 mb-2"
                        value={this.state.weight}
                        size="small"
                        shrink="true"
                        onChange={(e) =>
                            this.calculateAmount("weight", e.target.value)
                        }
                    />
                    <TextField
                        id="rate"
                        label="Rate"
                        variant="outlined"
                        type="number"
                        className="mr-2 mb-2"
                        value={this.state.rate}
                        size="small"
                        onChange={(e) =>
                            this.calculateAmount("rate", e.target.value)
                        }
                    />
                    <TextField
                        id="amount"
                        label="Amount"
                        variant="outlined"
                        type="number"
                        className="mr-2 mb-2"
                        value={this.state.amount}
                        size="small"
                        onChange={(e) =>
                            this.setState({ amount: e.target.value })
                        }
                    />

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.addItems}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                    </Button>

                    <div
                        className="mt-1 p-2 measure"
                        ref={(el) => (this.componentRef = el)}
                    >
                        <div className="row">
                            <div className="col col-md-8 mx-auto">
                                <Card className="mt-2 p-0">
                                    <Card.Header>
                                        <p className="text-center pb-0 mb-4">
                                            || श्री गणेश प्रसन्न
                                            &nbsp;&nbsp;&nbsp;&nbsp; श्री खंडोबा
                                            प्रसन्न ||
                                        </p>
                                        <Card.Title className="text-center pb-0 mb-0">
                                            <b>Diksha Vegetables</b>
                                        </Card.Title>
                                        <p className="text-center pb-0 mb-0">
                                            Y. Mangewadi, Tal. Sangola, Dist.
                                            Solapur, Maharashtra
                                        </p>
                                        <p className="text-center">
                                            Pro. Ajay Yelpale &nbsp;&nbsp;
                                            Mob.No. 9623659519, 7218520520
                                        </p>
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <p>
                                                Date:{" "}
                                                <b>
                                                    {moment(
                                                        this.state.date
                                                    ).format("DD / MM / YYYY")}
                                                </b>
                                            </p>

                                            <p>
                                                Bill No.:{" "}
                                                <b>{this.state.memoId}</b>
                                            </p>
                                        </span>
                                        <span className="d-flex justify-content-center">
                                            <div className="border border-danger rounded-pill px-5 text-danger">
                                                DELIVERY MEMO
                                            </div>
                                        </span>
                                    </Card.Header>
                                    <Card.Body className="pb-3 mb-0">
                                        <div className="row">
                                            <div className="col" md={6}>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Merchant's name:{" "}
                                                    {this.state.customerName}
                                                </h6>
                                            </div>
                                            <div className="col" md={6}>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Vehicle Number:{" "}
                                                    {this.state.vehicleNo}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" md={12}>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Address:{" "}
                                                    {this.state.address}
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Body>
                                    <Card.Body className="m-0 pt-0">
                                        {/* Order overview */}
                                        <Tbl striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Particular</th>
                                                    <th>Mark</th>
                                                    <th>Total Boxes</th>
                                                    <th>Weight / Box</th>
                                                    <th>Total Weight</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            {this.state.itemList.length > 0 ? (
                                                <tbody>
                                                    {this.state.itemList.map(
                                                        (item, index) => {
                                                            console.log(
                                                                "index: ",
                                                                index
                                                            );
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {index +
                                                                            1}{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.particular
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.mark
                                                                        }{" "}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.totalBoxes
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.weightPerBox
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.weight
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.rate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amount
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        className="d-print-none"
                                                                        align="center"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrash
                                                                            }
                                                                            onClick={() =>
                                                                                this.deleteItem(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="8">
                                                            No items added
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Tbl>
                                    </Card.Body>
                                    <Card.Footer className="pb-3 mb-0">
                                        <div className="row">
                                            <div className="col" md={6}>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Advance:{" "}
                                                    {this.state.advance}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Balance:{" "}
                                                    {this.state.balance}
                                                </h6>
                                            </div>
                                            <div className="col" md={6}>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Lorry freight:{" "}
                                                    {this.state.lorryFreight}
                                                </h6>
                                                <h6
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    Driver Mobile No:{" "}
                                                    {this.state.driverMobile}
                                                </h6>
                                            </div>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                className="mt-2 mr-1"
                                color="primary"
                                variant="contained"
                                style={{ float: "right" }}
                                disabled={
                                    this.state.customerName &&
                                    this.state.address
                                        ? false
                                        : true
                                }
                                onClick={this.handleSavePrint}
                            >
                                Print Bill
                            </Button>
                        )}
                        content={() => this.componentRef}
                    />

                    <Button
                        className="mt-2 mr-1"
                        color="secondary"
                        variant="contained"
                        style={{ float: "right" }}
                        type="submit"
                        disabled={
                            this.state.customerName && this.state.address
                                ? false
                                : true
                        }
                        onClick={this.handleSave}
                    >
                        Save bill
                    </Button>
                    <Button
                        className="mt-2 mr-1"
                        color="info"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleClear}
                    >
                        clear
                    </Button>
                </div>
            </div>
        );
    }
}
