import React, { Component } from "react";
import ReactToPrint from "react-to-print";

import {
    Row,
    Col,
    Card,
    Badge,
    Table as Tbl,
    Form,
    Modal,
} from "react-bootstrap";

import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenAlt,
    faPlusCircle,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { API_URL } from "./../../../global";
const axios = require("axios");

export default class UpdateBPL extends Component {
    constructor(props) {
        super();
        this.state = {
            modal: false,

            id: props.id,
            initializeData: props.initializeData,
            capBoxPackingBill: null,
            capBoxPackingList: null,

            partyId: 0,
            customerName: null,
            address: null,
            vehicleNo: null,
            vehicleDispatchTime: null,
            lorryFreight: null,
            transportName: null,
            inam1: null,
            inam2: null,
            driverMobile: null,
            date: null,
            advance: null,
            balance: null,

            totalBoxes: 0,

            varietyName: null,
            mark: null,
            boxQty: null,
            weightPerBox: null,
            total: 0,

            printRef: null,
        };
    }

    openModal = () => {
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    presetOldData = () => {
        this.setState({ partyId: this.state.capBoxPackingBill.partyId });
        this.setState({
            customerName: this.state.capBoxPackingBill.customerName,
        });
        this.setState({ address: this.state.capBoxPackingBill.address });
        this.setState({ vehicleNo: this.state.capBoxPackingBill.vehicleNo });
        this.setState({
            vehicleDispatchTime: this.state.capBoxPackingBill
                .vehicleDispatchTime,
        });
        this.setState({
            transportName: this.state.capBoxPackingBill.transportName,
        });
        this.setState({ inam1: this.state.capBoxPackingBill.inam1 });
        this.setState({ inam2: this.state.capBoxPackingBill.inam2 });
        this.setState({
            lorryFreight: this.state.capBoxPackingBill.lorryFreight,
        });
        this.setState({ advance: this.state.capBoxPackingBill.advance });
        this.setState({ balance: this.state.capBoxPackingBill.advance });
        this.setState({ totalBoxes: this.state.capBoxPackingBill.totalBoxes });
        this.setState({
            driverMobile: this.state.capBoxPackingBill.driverMobile,
        });
        this.setState({ date: this.state.capBoxPackingBill.date });
    };

    getBPL = () => {
        let url = API_URL;
        const query = `SELECT b.*, p.name as customerName, p.address as address FROM capBoxPackingBill as b inner join party as p where b.partyId = p.id AND b.id=${this.state.id}`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("capBoxPackingBill data: ", res.data);
                this.setState({ capBoxPackingBill: res.data[0] });
                this.presetOldData();
            })
            .catch((err) => {
                console.log("capBoxPackingBill fetch error: ", err);
            });
    };

    getBPLList = () => {
        let url = API_URL;
        const query = `SELECT * FROM capBoxPackingList WHERE bplId=${this.state.id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("capBoxPackingList data: ", res.data);
                this.setState({ capBoxPackingList: res.data });
            })
            .catch((err) => {
                console.log("capBoxPackingList fetch error: ", err);
            });
    };

    calculateAmount = (field, value) => {
        if (field === "weight") {
            let amount = value * this.state.rate;
            this.setState({ weight: value });
            this.setState({ amount: amount });
        } else if (field === "rate") {
            let amount = this.state.weight * value;
            this.setState({ rate: value });
            this.setState({ amount: amount });
        }
    };

    addItems = () => {
        // fetch previous items
        let items = this.state.capBoxPackingList;

        // update total
        let totalBoxes = this.state.totalBoxes + Number(this.state.boxQty);
        this.setState({ totalBoxes: totalBoxes });

        // add new item
        items.push({
            varietyName: this.state.varietyName,
            mark: this.state.mark,
            boxQty: this.state.boxQty,
            weightPerBox: this.state.weightPerBox,
        });

        // set state
        this.setState({ capBoxPackingList: items });
    };

    deleteItem = (index) => {
        let capBoxPackingList = this.state.capBoxPackingList;

        // update total boxes
        let totalBoxes =
            this.state.totalBoxes - capBoxPackingList[index]["boxQty"];
        this.setState({ totalBoxes: totalBoxes });

        // remove element
        let updatedList = capBoxPackingList.filter((item, _index) => {
            if (index !== _index) return item;
        });
        this.setState({ capBoxPackingList: updatedList });
    };

    handleClear = () => {
        this.setState({ capBoxPackingList: [] });
    };

    updateBPLList = () => {
        let url = API_URL;

        // 2. delete previous
        this.state.capBoxPackingList.map((item, index) => {
            const query = `DELETE FROM capBoxPackingList WHERE partyId=${this.state.partyId} AND bplId=${this.state.id}`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "deleted previous records from capBoxPackingList successfully"
                    );
                })
                .catch((err) => {
                    console.log(
                        "failed to delete previous records from capBoxPackingList, error: ",
                        err
                    );
                });
        });

        // 1.  insert updated list into capBoxPackingList
        this.state.capBoxPackingList.map((item, index) => {
            const query = `INSERT INTO capBoxPackingList(partyId, bplId, varietyName, mark, boxQty, weightPerBox) VALUES(${this.state.partyId}, ${this.state.id}, '${item.varietyName}', '${item.mark}', ${item.boxQty}, ${item.weightPerBox})`;
            let data = { crossDomain: true, crossOrigin: true, query: query };
            axios
                .post(url, data)
                .then((res) => {
                    console.log(
                        "insert boxPackingList successful, index: ",
                        index
                    );
                })
                .catch((err) => {
                    console.log(
                        "failed to insert boxPackingList, error: ",
                        err
                    );
                });
        });
    };

    handleUpdate = () => {
        let url = API_URL;

        // 1.  insert into deliveryMemo
        const query = `UPDATE capBoxPackingBill SET date='${this.state.date}', vehicleNo='${this.state.vehicleNo}', lorryFreight=${this.state.lorryFreight}, driverMobile='${this.state.driverMobile}', advance=${this.state.advance}, balance=${this.state.balance}, vehicleDispatchTime='${this.state.vehicleDispatchTime}', transportName='${this.state.transportName}', inam1='${this.state.inam1}', inam2='${this.state.inam2}', totalBoxes=${this.state.totalBoxes} WHERE id=${this.state.id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(url, data)
            .then((res) => {
                console.log("update capBoxPackingBill successful");
                this.updateBPLList();
                toast.success("Box Packing Bill updated successfully");
                this.state.initializeData();
            })
            .catch((err) => {
                console.log("failed to update capBoxPackingBill, error: ", err);
            });
    };

    handleUpdatePrint = (e) => {
        // 1. handle update
        this.handleUpdate();

        // 2. handle print
    };

    componentDidMount() {
        this.getBPL();
        this.getBPLList();
    }

    renderModal() {
        if (this.state.capBoxPackingBill == null) return null;

        return (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>BPL #{this.state.capBoxPackingBill.id}</h5>
                    </Modal.Title>
                </Modal.Header>

                <div className="m-2">
                    <TextField
                        id="customerName"
                        label="Party Name"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.customerName}
                        size="small"
                        onChange={(e) =>
                            this.setState({ customerName: e.target.value })
                        }
                        required="true"
                        disabled
                    />

                    <TextField
                        id="custAddress"
                        label="Address"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.address}
                        onChange={(e) => {
                            this.setState({ address: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="vehicleNo"
                        label="Vehicle Number"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.vehicleNo}
                        onChange={(e) => {
                            this.setState({ vehicleNo: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="vehicleDispatchTime"
                        type="datetime-local"
                        label="Vehicle Dispatch Time"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={
                            this.state.vehicleDispatchTime == null
                                ? ""
                                : moment(this.state.vehicleDispatchTime).format(
                                      "DD-MM-YYYY HH:MM"
                                  )
                        }
                        onChange={(e) => {
                            this.setState({
                                vehicleDispatchTime: e.target.value,
                            });
                        }}
                        required="true"
                        size="small"
                    />
                    <FormControl
                        variant="filled"
                        className="mr-2 mb-2"
                        style={{ minWidth: "180px" }}
                        size="small"
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Transport name
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(e) => {
                                this.setState({
                                    transportName: e.target.value,
                                });
                            }}
                            name="transportName"
                            value={this.state.transportName}
                        >
                            <MenuItem value="Eicher">Eicher</MenuItem>
                            <MenuItem value="Truck">Truck</MenuItem>
                            <MenuItem value="Pikup">Pikup</MenuItem>
                            <MenuItem value="Train">Train</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="inam1"
                        type="text"
                        label="inam 1st"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.inam1}
                        onChange={(e) => {
                            this.setState({ inam1: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="inam1"
                        type="text"
                        label="inam 2nd"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.inam2}
                        onChange={(e) => {
                            this.setState({ inam2: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="frieght"
                        type="number"
                        label="Lorry Freight"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.lorryFreight}
                        onChange={(e) => {
                            this.setState({ lorryFreight: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="advance"
                        type="text"
                        label="Advance"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.advance}
                        onChange={(e) => {
                            this.setState({ advance: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="balance"
                        type="text"
                        label="Balance"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.balance}
                        onChange={(e) => {
                            this.setState({ balance: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="driverMobile"
                        type="text"
                        label="Driver Mobile No"
                        variant="outlined"
                        className="mr-2 mb-2"
                        value={this.state.driverMobile}
                        onChange={(e) => {
                            this.setState({ driverMobile: e.target.value });
                        }}
                        required="true"
                        size="small"
                    />
                    <TextField
                        id="date"
                        label="Date"
                        variant="outlined"
                        type="date"
                        className="mr-2 mb-2"
                        value={moment(this.state.date).format("DD-MM-YYYY")}
                        size="small"
                        onChange={(e) =>
                            this.setState({ date: e.target.value })
                        }
                    />
                    <hr />
                    <TextField
                        id="varietyName"
                        label="Variety Name"
                        variant="outlined"
                        type="text"
                        className="mr-2"
                        value={this.state.varietyName}
                        onChange={(e) => {
                            this.setState({ varietyName: e.target.value });
                        }}
                        size="small"
                    />
                    <TextField
                        id="mark"
                        label="Mark"
                        variant="outlined"
                        type="text"
                        className="mr-2"
                        value={this.state.mark}
                        onChange={(e) => {
                            this.setState({ mark: e.target.value });
                        }}
                        size="small"
                    />

                    <TextField
                        id="boxQty"
                        label="Box Quantity"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.boxQty}
                        onChange={(e) => {
                            this.setState({ boxQty: e.target.value });
                        }}
                        size="small"
                    />
                    <TextField
                        id="weightPerBox"
                        label="WeightPerBox"
                        variant="outlined"
                        type="number"
                        className="mr-2"
                        value={this.state.weightPerBox}
                        onChange={(e) => {
                            this.setState({ weightPerBox: e.target.value });
                        }}
                        size="small"
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.addItems}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                    </Button>
                </div>
                <Modal.Body ref={(el) => (this.componentRef = el)}>
                    <Card className="mt-2 p-0">
                        <Card.Header>
                            <p className="text-center pb-0 mb-4">
                                || श्री गणेश प्रसन्न &nbsp;&nbsp;&nbsp;&nbsp;
                                श्री खंडोबा प्रसन्न ||
                            </p>
                            <Card.Title className="text-center pb-0 mb-0">
                                <b>Diksha Vegetables</b>
                            </Card.Title>
                            <p className="text-center pb-0 mb-0">
                                Y. Mangewadi, Tal. Sangola, Dist. Solapur,
                                Maharashtra
                            </p>
                            <p className="text-center">
                                Pro. Ajay Yelpale &nbsp;&nbsp; Mob.No.
                                9623659519, 7218520520
                            </p>
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <p>
                                    Date{" "}
                                    <b>
                                        {moment(this.state.date).format(
                                            "DD / MM / YYYY"
                                        )}
                                    </b>
                                </p>

                                <p>
                                    Bill No. <b>{this.state.id}</b>
                                </p>
                            </span>
                            <span className="d-flex justify-content-center">
                                <div className="border border-danger rounded-pill px-5 text-danger">
                                    BOX PACKING LIST
                                </div>
                            </span>
                        </Card.Header>
                        <Card.Body className="pb-3 mb-0">
                            <Row>
                                <Col md={6}>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Merchant's name:{" "}
                                        {this.state.customerName}
                                    </h6>
                                </Col>
                                <Col md={6}>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Address: {this.state.address}
                                    </h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Vehicle Number: {this.state.vehicleNo}
                                    </h6>
                                </Col>
                                <Col md={6}>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Lorry Freight: {this.state.lorryFreight}
                                    </h6>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Body className="m-0 pt-0">
                            {/* Order overview */}
                            <Tbl striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Variety Name</th>
                                        <th>Mark</th>
                                        <th>Box Quantity</th>
                                        <th>Weight / Box</th>
                                    </tr>
                                </thead>
                                {this.state.capBoxPackingList != null ? (
                                    <tbody>
                                        {this.state.capBoxPackingList.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.varietyName}{" "}
                                                        </td>
                                                        <td>{item.mark}</td>
                                                        <td>{item.boxQty}</td>
                                                        <td>
                                                            {item.weightPerBox}
                                                        </td>
                                                        <td
                                                            className="d-print-none"
                                                            align="center"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                onClick={() =>
                                                                    this.deleteItem(
                                                                        index
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">No items added</td>
                                        </tr>
                                    </tbody>
                                )}
                            </Tbl>
                        </Card.Body>
                        <Card.Footer className="pb-3 mb-0">
                            <Row>
                                <Col md={6}>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Total Boxes: {this.state.totalBoxes}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Vehicle Dispatch Time :{" "}
                                        {moment(
                                            this.state.vehicleDispatchTime
                                        ).format("DD / MM / YYYY HH:MM")}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Inam 1<sup>st</sup> : {this.state.inam1}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Inam 2<sup>nd</sup> : {this.state.inam2}
                                    </h6>
                                </Col>
                                <Col md={6}>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Advance : {this.state.advance}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Balance : {this.state.balance}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Transport name :{" "}
                                        {this.state.transportName}
                                    </h6>
                                    <h6 style={{ textTransform: "capitalize" }}>
                                        Driver mobile no. :{" "}
                                        {this.state.driverMobile}
                                    </h6>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="mt-2 mr-1"
                        color="secondary"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={this.handleUpdate}
                    >
                        Update bill
                    </Button>
                </Modal.Footer>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Button
                    color="secondary"
                    variant="contained"
                    className="mt-2"
                    onClick={this.openModal}
                >
                    <FontAwesomeIcon icon={faPenAlt} />
                </Button>

                <Modal
                    show={this.state.modal}
                    onHide={this.closeModal}
                    size="xl"
                    dialogClassName="modal-90w"
                >
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}
