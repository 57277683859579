import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import CSS styles

import capsicum from "./../images/capsicum.png";
import pom from "./../images/pomegranate.png";

function Main() {

	const  userName  = "user1";
	function logout() {
	}
	return (
		<div>
			<div class="jumbotron" style={{backgroundColor: '#f6f6f6'}}>
				<h1 class="display-4 text-center">Diksha Fruits & Suppliers</h1>
				<Row>
					<Col className="text-right">
					<Link to="/cmain"><img src={capsicum} /></Link>
						
					</Col>
					<Col className="text-left">
					<Link to="/pmain"><img src={pom} /></Link>
					</Col>
				</Row>
			</div>
			<p className="text-center">designed & developed by 5TechG Lab LLP</p>
		</div>	
	);
}

export default Main;