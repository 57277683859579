import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// import CSS styles
import "./App.css";

// import components
import Login from "./components/auth/login.js";
import Main from "./components/Main";
import CMain from "./components/capsicum/main_dashboard/Main";
// import PMain from "./components/pomegranate/main_dashboard/Main";

// import context

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/main" component={Main}></Route>
        <Route exact path="/cmain" component={CMain} />
        <Route path="/pmain" exact component={CMain} />
      </Switch>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
    </Router>
  );
}

export default App;
